import { memo } from 'react';
import { GetServerSideProps, InferGetServerSidePropsType } from 'next';
import Head from 'next/head';
import Link from 'next/link';
import { Icon } from '@cbhq/cds-web/icons';
import { HeroSquare, Pictogram } from '@cbhq/cds-web/illustrations';
import { Box, HStack, Spacer, VStack } from '@cbhq/cds-web/layout';
import { Pressable } from '@cbhq/cds-web/system';
import {
  Link as CDSLink,
  TextBody,
  TextLabel1,
  TextTitle1,
  TextTitle3,
} from '@cbhq/cds-web/typography';

import Header from ':data-marketplace/components/Header/Header';
import ProductsFilters from ':data-marketplace/components/ProductsFilters/ProductsFilters';
import { ProductsTable } from ':data-marketplace/components/ProductsTable/ProductsTable';
import { categories } from ':data-marketplace/data/categories';
import { Product } from ':data-marketplace/types/Product';
import { getCategoryIcon, getCategoryProducts } from ':data-marketplace/utils/categories';
import {
  DEFAULT_PAGE_SIZE,
  QUERY_PARAM_PRODUCT_PAGE,
  RELATED_CONTENT_SIDEBAR_WIDTH,
} from ':data-marketplace/utils/constants';
import { getDataFilterIdsFromParams } from ':data-marketplace/utils/dataHierarchy';
import {
  EventActionType,
  EventComponentType,
  EventUUID,
  sendEventCallback,
} from ':data-marketplace/utils/events';
import { VerticalOverflow } from ':data-marketplace/utils/hacks';
import { getFilteredProducts, getProducts } from ':data-marketplace/utils/products';
import { getCategoryUrl } from ':data-marketplace/utils/url';

export type Props = {
  products: Product[];
  productCount: number;
  pageSize: number;
  currentPage: number;
};

export const getServerSideProps: GetServerSideProps<Props> = async (context) => {
  // Product Table Pagination
  const currentPage = Number(context.query[QUERY_PARAM_PRODUCT_PAGE]) || 1;
  const pageSize = DEFAULT_PAGE_SIZE;
  const startPageIndex = (currentPage - 1) * pageSize;
  const endPageIndex = currentPage * pageSize;

  // Product Data
  const filters = getDataFilterIdsFromParams(context.params, context.query);
  const pageProducts = getProducts({ ...filters });
  const filteredProducts = getFilteredProducts(pageProducts, context.query);
  const displayedProducts = filteredProducts.slice(startPageIndex, endPageIndex);

  return {
    props: {
      products: displayedProducts,
      productCount: filteredProducts.length,
      currentPage,
      pageSize,
    },
  };
};

const title = 'Data Marketplace';
const description =
  'Enhance your crypto investment strategies, services, and research with ready to use, high quality historical datasets from Coinbase Exchange and the broader crypto ecosystem.';

const Home = memo(function Home({
  products,
  productCount,
  pageSize,
  currentPage,
}: InferGetServerSidePropsType<typeof getServerSideProps>) {
  return (
    <>
      <Head>
        <title key="title">{title}</title>
        <meta property="og:title" name="og:title" content={title} />
        <meta name="twitter:title" content={title} />
        <meta property="description" name="description" content={description} />
        <meta property="og:description" name="og:description" content={description} />
        <meta property="twitter:description" name="twitter:description" content={description} />
      </Head>

      <Header title="Data Marketplace" />

      <VStack flexGrow={1} dangerouslySetClassName={VerticalOverflow}>
        <HStack background="primary" alignItems="center">
          <Box flexDirection="column" spacing={7} maxWidth="875px">
            <TextTitle1 as="h2" color="secondary" spacingBottom={3}>
              Data for the Crypto Economy.
            </TextTitle1>

            <TextBody as="p" color="secondary">
              {description}
            </TextBody>
          </Box>

          <Spacer />

          <HeroSquare name="powerOfCrypto" />
        </HStack>

        <HStack flexGrow={1}>
          <VStack
            flexShrink={1}
            flexBasis={`calc(100% - ${RELATED_CONTENT_SIDEBAR_WIDTH})`}
            dangerouslySetClassName={VerticalOverflow}
            borderedEnd
          >
            <VStack spacing={7} borderedBottom>
              <TextTitle3 as="h2" spacingBottom={3}>
                Categories ({categories.length})
              </TextTitle3>

              <HStack gap={3}>
                {categories.map((category) => {
                  const categoryIcon = getCategoryIcon(category.id);
                  const categoryProducts = getCategoryProducts(category.id);
                  const categoryUrl = getCategoryUrl(category.id);

                  return (
                    <Link key={category.id} href={`/${categoryUrl}`} passHref legacyBehavior>
                      <Pressable as="a" backgroundColor="secondary" noScaleOnPress block>
                        <VStack
                          key={category.id}
                          spacing={7}
                          bordered
                          borderRadius="rounded"
                          height="100%"
                        >
                          <HStack gap={2} alignItems="center" spacingBottom={3}>
                            {categoryIcon ? (
                              <Pictogram name={categoryIcon} scaleMultiplier={0.5} />
                            ) : null}

                            <TextTitle3 as="h3">{category.name}</TextTitle3>
                          </HStack>

                          <TextBody as="p" spacingBottom={3}>
                            {category.description}
                          </TextBody>

                          <Spacer />

                          <HStack gap={3}>
                            <TextLabel1 as="span" transform="uppercase" color="foregroundMuted">
                              {category.packages.length} packages
                            </TextLabel1>
                            <TextLabel1 as="span" transform="uppercase" color="foregroundMuted">
                              {categoryProducts.length} products
                            </TextLabel1>
                          </HStack>
                        </VStack>
                      </Pressable>
                    </Link>
                  );
                })}
              </HStack>
            </VStack>

            <VStack flexGrow={1} gap={3} spacing={7}>
              <TextTitle3 as="h2">Products ({productCount})</TextTitle3>

              <ProductsFilters itemCount={productCount} />

              <ProductsTable
                products={products}
                itemCount={productCount}
                currentPage={currentPage}
                pageSize={pageSize}
              />
            </VStack>
          </VStack>

          <VStack
            flexShrink={0}
            flexBasis={RELATED_CONTENT_SIDEBAR_WIDTH}
            dangerouslySetClassName={VerticalOverflow}
          >
            <VStack borderedBottom gap={3} spacing={7} background="backgroundAlternate">
              <Icon name="api" size="l" />

              <TextTitle3 as="h2">Looking to access data?</TextTitle3>

              <TextBody as="p">
                Browse our fast growing crypto data catalog and get in touch with our team to learn
                more.
              </TextBody>

              <Link href="/contact" passHref>
                <CDSLink
                  variant="headline"
                  onClick={sendEventCallback(
                    EventUUID.suggestion_contact,
                    EventActionType.click,
                    EventComponentType.link,
                  )}
                >
                  Contact sales
                </CDSLink>
              </Link>
            </VStack>
          </VStack>
        </HStack>
      </VStack>
    </>
  );
});

export default Home;
